<script>
    export let size = 25;
</script>

<style>
</style>

<svg class="animate-spin" viewBox="0 0 24 24" width="{size}" height="{size}" xmlns="http://www.w3.org/2000/svg">
    <path d="M 2 12 A 10 10, 0, 1, 1, 12 22" fill="white" stroke="blue" opacity="0.25" stroke-width="4"/>
    <path d="M 2 12 A 10 10, 0, 0, 0, 12 22" fill="white" stroke="blue" opacity="0.5" stroke-width="4"/>
</svg>
